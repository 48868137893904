// colors
$light-grey: #F5F5F7;
$normal-grey: #e2e2e2;
$dark-grey: #86868b;
$blue: #006ddb;

// breakpoints

$screen-xs: 480px;
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;
$screen-xxl: 1536px;

// other
$fontSize: 2rem;
$fontSizeSmall: 1.6rem;
$max-width: 1280px;
$basic-unit: 8px;
$headerHeight: 50px;



@import '/src/sass/global.scss';

.intro {
  width: 94%;
  max-width: $max-width;
  margin: 0 auto;
  padding-bottom: item(20);

  .main {
    height: 100vh;

    .title {
      width: 100%;
      margin: 15vh auto;

      .profileImg {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto item(2);
        border-radius: 50%;
      }

      span {
        display: block;
        font-size: 2.4rem;
        font-weight: 600;
        text-align: center;
        width: 100%;

        @include md {
          font-size: 3.2rem;
        }
      }

      h1 {
        width: 100%;
        margin: 2rem auto;
        font-size: 4rem;
        text-align: center;

        @include md {
          font-size: 4.8rem;
        }
      }

      @include md {
        width: 50%
      }
    }
  }

  .subtitle {
    @include rainbowText;
  }
}
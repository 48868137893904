// reset
@import-normalize;

@import './mixins';
@import './functions';

body, h1, h2, h3, h4, div, p, ul, li {
  margin: 0;
  padding: 0;
}

ul, li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

// basic settings
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;

  @include sm {
    font-size: 8px;
  }
}

body * {
  box-sizing: border-box;
}




@import './sass/global';

.container {
  max-width: $max-width;
  margin: 0 auto;
}


.mobile {
    @include lg {
        display: none;
    }
}